<template>
  <div class="new-project">
    <div class="new-project__title">
      <router-link :to="{ name: 'Projects' }" class="new-project__title-link">
        <img src="@/assets/icons/icon-arrow.svg" alt="" />
      </router-link>
      <PageTitle text="Новый проект" />
    </div>
    <div class="new-project__info">
      <ProjectCard />
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/elements/PageTitle'
import ProjectCard from '../components/blocks/ProjectCard'

export default {
  name: 'NewProject',
  components: {
    PageTitle,
    ProjectCard,
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.new-project {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
    position: relative;

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.04);
      border-radius: 6px;
      background: #fff;
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 1rem;
      padding-right: 0.2rem;
      transition: 0.3s ease-out;

      &:hover {
        box-shadow: 4px 4px 24px rgba(70, 80, 119, 0.44);
      }
    }
  }

  &__info {
    background: #fff;
    padding: 4rem 3.2rem;
  }
}

@media (max-width: $screen-xs-max){
	.new-project__title-link{
		display: none;
	}
	.new-project__info{
		margin: 0 -16px;
		padding: 24px 16px;
	}
}
</style>
