var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-project"
  }, [_c('div', {
    staticClass: "new-project__title"
  }, [_c('router-link', {
    staticClass: "new-project__title-link",
    attrs: {
      "to": {
        name: 'Projects'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-arrow.svg"),
      "alt": ""
    }
  })]), _c('PageTitle', {
    attrs: {
      "text": "Новый проект"
    }
  })], 1), _c('div', {
    staticClass: "new-project__info"
  }, [_c('ProjectCard')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }